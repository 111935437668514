import type { Ref } from 'vue'
import {
  buildEventsByEntityMap,
  type EventsByEntityType,
} from 'markets-store/helpers/filterCustomMenu'
import { filterMap } from '@st/utils'
import type { CustomMenuItem, CustomMenuClass } from 'markets-store/types'
import { getMenuItemIcon } from 'markets-store/helpers/getMenuItemIcon'
import { CYBER_SPORT_ID } from 'markets-store/constants'
import { useMarketsStore } from './useMarketsStore'
import type { ExtendedMenuItem } from '../types'

interface UseSportMenuReturn {
  menu: Readonly<Ref<ExtendedMenuItem[]>>
  liveEventsMap: Readonly<Ref<EventsByEntityType>>
}

function getLink(item: CustomMenuItem) {
  switch (item.type) {
    case 'sport':
      return `/cybersport`
    case 'category':
      return `/cybersport/${item.id}`
    case 'tournament':
      return `/cybersport/${item.categoryId}/${item.id}`
    default:
      return `/cybersport/${item.id}`
  }
}

interface FilterAndFormatMenuParams {
  menu: CustomMenuItem[]
  eventsMap: EventsByEntityType
  liveEventsMap: EventsByEntityType
  level?: number
  skipEventsCheck?: boolean
}

function filterAndFormatMenu({
  level = 0,
  eventsMap,
  liveEventsMap,
  menu,
  skipEventsCheck,
}: FilterAndFormatMenuParams): ExtendedMenuItem[] {
  const levelForIcon = level === 1 ? 0 : level
  return filterMap(
    menu,
    (item) => skipEventsCheck || eventsMap[item.entityId]?.size > 0,
    (item) => ({
      ...item,
      children: item.children
        ? filterAndFormatMenu({
            menu: item.children,
            eventsMap,
            liveEventsMap,
            level: level + 1,
            skipEventsCheck,
          })
        : undefined,
      liveSportEventsNumber: liveEventsMap[item.entityId]?.size ?? 0,
      icon: getMenuItemIcon({ item, level: levelForIcon })?.name,
      link: getLink(item),
      mobileLink: `/mobile${getLink(item)}`,
    }),
  )
}

export function useCybersportMenu(): UseSportMenuReturn {
  const MS = useMarketsStore()
  const customMenu = ref(MS.customMenu) as Ref<CustomMenuClass>

  const events = useSportEventsBySportId(CYBER_SPORT_ID)
  const liveEvents = useSportEventsBySportId(CYBER_SPORT_ID, 'live')

  const actualStates = ref(MS.systemStates)
  const isActualFullyLoaded = computed<boolean>(
    () => actualStates.value.fullActualLoaded,
  )

  const eventsMap = computed(() =>
    buildEventsByEntityMap(customMenu.value, events.value),
  )
  const liveEventsMap = computed(() =>
    buildEventsByEntityMap(customMenu.value, liveEvents.value),
  )

  const menuWithOnlyCybersport = computed(() =>
    customMenu.value.menu.filter(
      (item) => item.type === 'sport' && item.id === CYBER_SPORT_ID,
    ),
  )

  const menu = computed(() =>
    filterAndFormatMenu({
      menu: menuWithOnlyCybersport.value,
      eventsMap: eventsMap.value,
      liveEventsMap: liveEventsMap.value,
      skipEventsCheck: !isActualFullyLoaded.value,
    }),
  )

  return { menu, liveEventsMap }
}
